import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import FeedbackForm from "../components/forms/feedback"

export default function Feedback({ data }) {
   const title  = data.components.title
  const components = data.components.relationships.field_form_text_components
  const topText = components[0]
  const contactDetails = components[1]
  const bottomText = components[2]
  return (
  <Layout hideCta="true" >
    <div className="relative bg-gray-50">
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 pb-16 pt-32 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 md:pt-40 lg:pt-48 lg:pb-24">
          <div className="mx-auto max-w-lg lg:max-w-none">
           <div className="mx-auto text-base leading-6 text-gray-500">
            <h1 className="mt-2 mb-6 text-2xl font-bold tracking-tight text-sky-900 sm:text-3xl">{title}</h1>
            <div className="textarea" dangerouslySetInnerHTML={{ __html: topText.field_news_text_body.processed }} />
              <div className="my-8 text-base text-sky-900">
                {contactDetails.field_contact_phone && <div className="mt-6">
                  <div className="sr-only">Phone number</div>
                  <div className="flex">
                    <PhoneIcon className="h-6 w-6 flex-shrink-0 text-sky-700" aria-hidden="true" />
                    <span className="ml-3 text-gray-900 font-semibold">{contactDetails.field_contact_phone}</span>
                  </div>
                </div>}
                {contactDetails.field_contact_email && <div className="mt-3">
                  <div className="sr-only">Email</div>
                  <div className="flex">
                    <EnvelopeIcon className="h-6 w-6 font-semibold flex-shrink-0 text-sky-700" aria-hidden="true" />
                    <span className="ml-3 text-gray-900 font-semibold">{contactDetails.field_contact_email}</span>
                  </div>
                </div>}
              </div>
              <div className="textarea" dangerouslySetInnerHTML={{ __html: bottomText.field_news_text_body.processed }} />
            </div>
          </div>
        </div>
        <div className="pt-16 bg-white py-16 lg:col-span-2 lg:px-8 lg:pt-48 lg:pb-24">
            <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:px-0 lg:max-w-7xl lg:pt-16">
                <div className="grid grid-cols-1 gap-y-6">
                  <FeedbackForm />
                </div>
            </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}feedback-and-complaints/`
  const metaDescription = data.components?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>Feedback and complaints | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query FeedbackPageQuery {
    components: nodeForm(drupal_internal__nid: {eq: 13}) {
      field_metatags {
        description
      }
      title
      relationships {
        field_form_text_components {
          ... on paragraph__contact_info {
            field_contact_email
            field_contact_phone
          }
          ... on paragraph__text {
            field_news_text_body {
              processed
            }
          }
        }
      }
    }
  }
`

