import * as React from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import toast from "react-hot-toast"
import { saveFormSubmission } from "../../utils/helpers"

const FeedbackForm = () => {
	const notify = () => toast(`Thank you for your enquiry. We'll be in touch soon.`, {
		position: "top-center",
		id: "clipboard",
	})
  return (
  	<Formik
  		validateOnChange={values => {
	      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	      const errors = {};
	      if (!values.first_name) {
	        errors.first_name = `First name is required`
	      }
	      if (!values.last_name) {
	        errors.last_name = `Last name is required`
	      }
	      if (!values.email || !emailRegex.test(values.email)) {
	        errors.email = `A valid email address is required`
	      }
	      if (!values.postcode) {
	        errors.postcode = `Postcode/City is required`
	      }
	      if (!values.phone) {
	        errors.phone = `Phone number is required`
	      }
	      if (values.state === 'none') {
	        errors.state = `State is required`
	      }
	      if (values.feedback === 'none') {
	        errors.feedback = `Type of feedback is required`
	      }
	      if (!values.message) {
	        errors.message = `Message is required`
	      }
	      return errors
	    }}
  		validateOnBlur={false}
	    initialValues={{
	      first_name: ``,
	      last_name: ``,
	      email: ``,
	      phone: ``,
	      feedback: `none`,
	      postcode: ``,
	      state: `none`,
	      message: ``,
	      contact_me: false,
	    }}
	    onSubmit={(values, { setSubmitting, resetForm }) => {
	    	let data = values
	    	data.webform_id = `feedback`
	    	data.contact_me = values.contact_me === true ? true : false
	    	saveFormSubmission(data).then(response => {
	      	if (response.status === 200) {
	      		setSubmitting(true)
	      		resetForm()
	      		notify()
	      	}	
	      })
	    }}
	    validate={values => {
	      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	      const errors = {};
	      if (!values.first_name) {
	        errors.first_name = `First name is required`
	      }
	      if (!values.last_name) {
	        errors.last_name = `Last name is required`
	      }
	      if (!values.email || !emailRegex.test(values.email)) {
	        errors.email = `A valid email address is required`
	      }
	      if (!values.postcode) {
	        errors.postcode = `Postcode/City is required`
	      }
	      if (!values.phone) {
	        errors.phone = `Phone number is required`
	      }
	      if (values.state === 'none') {
	        errors.state = `State is required`
	      }
	      if (values.feedback === 'none') {
	        errors.feedback = `Type of feedback is required`
	      }
	      if (!values.message) {
	        errors.message = `Message is required`
	      }
	      return errors
	    }}
	  >
	  {({ values, handleChange, handleBlur, setFieldValue }) => (
	    <Form>
	    <div className="grid grid-cols-1 gap-6">
			<div className="relative">
	 			<label className={values.feedback === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="feedback">Type of feedback</label>
		           <Field
		             component="select"
		             className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
		             name="feedback"
		           >	
		              <option value="none">- Select type of feedback -</option>
                      <option value="compliment">Compliment</option>
                      <option value="suggestion">Suggestion</option>
                      <option value="complaint">Complaint</option>
		           </Field>
		           <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="feedback" />
	           </div>
	      </div>
	     <div className="mt-6 grid grid-cols-2 gap-6">
		      <div className="relative">
			      <label className={values.first_name ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="first_name">First name</label>
			      <Field type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" name="first_name" placeholder="First name" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="first_name" />
		      </div>

		      <div className="relative">
			      <label className={values.last_name ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="last_name">Last name</label>
			      <Field name="last_name" type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Last name" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="last_name" />
		      </div>

		      <div className="relative">
			      <label className={values.email ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="email">Email</label>
			      <Field name="email" type="email" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Email" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="email" />
		      </div>

		      <div className="relative">
			      <label className={values.phone ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="phone">Phone</label>
			      <Field name="phone" type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Phone" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="phone" />
		      </div>

		      <div className="relative">
			      <label className={values.postcode ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="postcode">Postcode/City</label>
			      <Field 
			      		name="postcode"
			      		type="text" 
			      		className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" 
			      		placeholder="Postcode/City" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="postcode" />
		      </div>

		      <div className="relative">
	 			<label className={values.state === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="state">State</label>
		           <Field
		             component="select"
		             className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
		             name="state"
		           >	<option value="none">- Select state -</option>
			            <option value="ACT">Australian Capital Territory</option>
			            <option value="NSW">New South Wales</option>
			            <option value="NT">Northern Territory</option>
			            <option value="QLD">Queensland</option>
			            <option value="SA">South Australia</option>
			            <option value="TAS">Tasmania</option>
			            <option value="VIC">Victoria</option>
			            <option value="WA">Western Australia</option>
		           </Field>
		            <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="state" />
	           </div>
           </div>
			<div className="mt-6 grid grid-cols-1 gap-6">

			  <div className="relative">
			      <label className={values.message ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="message">Your message</label>
			      <Field name="message" 
			      		 className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" 
			      		 placeholder="Your message" 
			      		 component="textarea"
			      		 rows="4"
			      		 />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="message" />
		      </div>
		       <div className="relative">
		         <label>
		            <Field type="checkbox" name="contact_me" checked={values.contact_me} className="mr-2" onChange={(e) => {setFieldValue(`contact_me`,e.target.checked)}} />
		            <span className="text-sm">I would like to be contacted about my feedback</span>
		          </label>
 			</div>
			  <div className="form-item">
		      	<button aria-label="Submit contact form" type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-sky-650 pt-3 pb-2 px-6 text-base font-semibold text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Submit</button>
		      </div>
	      <	/div>
	    </Form>
	  )}
	  </Formik>
	)
}
export default FeedbackForm